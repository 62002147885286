/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "스탠다드아웃 83번째 로그에서는 Slack 장애, Zoom의 Keybase 인수, 새로운 맥북 13인치, Github 코드스페이스 공개 등에 대해 이야기를 나눴습니다."), "\n", React.createElement(_components.h3, null, "5월 12일 슬랙 장애"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://status.slack.com/2020-05/147dad376c8946ff"
  }, "May 12, 2020 Outage - Slack Status Site")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.githubstatus.com/"
  }, "GitHub Status")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://status.circleci.com/"
  }, "CircleCI Status")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://status.slack.com/"
  }, "Slack System Status")), "\n"), "\n", React.createElement(_components.h3, null, "RI / 세이빙스 플랜"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/news--aws-ec2-price-reduction-for-ri-and-savings-plans"
  }, "AWS, EC2 예약 인스턴스와 세이빙스 플랜에 적용되는 가격 인하 발표 | 44BITS")), "\n"), "\n", React.createElement(_components.h3, null, "Zoom, Keybase 인수"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://keybase.io/blog/keybase-joins-zoom"
  }, "Keybase joins Zoom")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.coindesk.com/stellar-to-airdrop-2-billion-xlm-into-keybase-wallets"
  }, "Stellar to Give Away 2 Billion XLM Valued at $120 Million Today - CoinDesk")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://snapcamera.snapchat.com/"
  }, "Snap Camera - Snap Camera")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://support.zoom.us/hc/en-us/articles/115000538083-Attendee-attention-tracking"
  }, "Attendee attention tracking – Zoom Help Center")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.theverge.com/2019/7/10/20689644/apple-zoom-web-server-automatic-removal-silent-update-webcam-vulnerability"
  }, "Apple is silently removing Zoom’s web server software from Macs - The Verge")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.bloter.net/archives/214715"
  }, "“카톡도 암호화됐숑~” | Bloter.net")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.sciencealert.com/this-new-hack-makes-your-pc-leak-sensitive-information-via-noises-from-the-hard-drive"
  }, "This New Hack Makes Your PC Leak Sensitive Information Via Noises From The Hard Drive")), "\n"), "\n", React.createElement(_components.h3, null, "애플 맥북 13인치 출시"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/kr/newsroom/2020/05/apple-updates-13-inch-macbook-pro-with-magic-keyboard-double-the-storage-and-faster-performance/"
  }, "Apple, Magic Keyboard와 두 배의 저장 공간, 그리고 빨라진 성능으로 13형 MacBook Pro 업데이트 - Apple (KR)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/kr/shop/buy-mac/macbook-pro/13%ED%98%95"
  }, "13형 MacBook Pro 구입하기 - Apple (KR)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://9to5mac.com/2020/05/11/14-inch-macbook-pro-2021/"
  }, "Rumor: 14-inch MacBook Pro to launch in 2021 - 9to5Mac")), "\n"), "\n", React.createElement(_components.h3, null, "비주얼 스튜디오 온라인 이름 변경"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://visualstudio.microsoft.com/services/visual-studio-codespaces/"
  }, "Visual Studio Codespaces - Cloud IDE - Code Editor Online")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://devblogs.microsoft.com/visualstudio/introducing-visual-studio-codespaces/"
  }, "Introducing Visual Studio Codespaces: cloud-hosted development for wherever you’re working | Visual Studio Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/features/codespaces/"
  }, "Codespaces")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.blog/2020-05-06-new-from-satellite-2020-github-codespaces-github-discussions-securing-code-in-private-repositories-and-more/"
  }, "New from Satellite 2020: GitHub Discussions, Codespaces, securing code in private repositories, and more - The GitHub Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/microsoft/monaco-editor"
  }, "microsoft/monaco-editor: A browser based code editor")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/cloud9/"
  }, "AWS Cloud9 Amazon Web Services")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://azure.microsoft.com/en-us/pricing/details/visual-studio-online/"
  }, "Visual Studio Online Pricing | Microsoft Azure")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://devblogs.microsoft.com/commandline/wsl2-will-be-generally-available-in-windows-10-version-2004/"
  }, "WSL2 will be generally available in Windows 10, version 2004 | Windows Command Line")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.publickey1.jp/blog/20/windows_10wsllinux.html"
  }, "(일본어) Windows 10のファイルエクスプローラがWSL機能を統合。Linuxを示すペンギンアイコンが登場 － Publickey")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/docker/for-mac/issues/3499"
  }, "com.docker.hyperkit 100% cpu usage is back again · Issue #3499 · docker/for-mac")), "\n"), "\n", React.createElement(_components.h3, null, "Backblaze의 스토리지, S3 API 호환"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.backblaze.com/b2/docs/s3_compatible_api.html"
  }, "BackblazeS3 Compatible API")), "\n"), "\n", React.createElement(_components.h3, null, "네이버, 자사주 매입하는 직원에게 연 최대 200만원 지원"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.v.daum.net/v/20200504162806157"
  }, "네이버, 자사주 매입하는 직원에게 연 최대 200만원 지원(종합) | Daum 뉴스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.zdnet.co.kr/view/?no=20181212221606"
  }, "네이버, 매년 전직원에 1천만원 스톡옵션 지급 - ZDNet korea")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.hani.co.kr/arti/economy/it/926248.html"
  }, "네이버 “자사주 55만주 소각”…‘라인 적자’ 주주 불만 달래나 : IT : 경제 : 뉴스 : 한겨레")), "\n"), "\n", React.createElement(_components.h3, null, "비상장 주식 거래"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hankyung.com/economy/article/201910143882Y"
  }, "두나무·삼성증권 이달 비상장 주식 거래 플랫폼 출시 | 한경닷컴")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://psx.or.kr/"
  }, "안전한 장외주식 PSX")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://psx.or.kr/sell/153/?list=/company/kurly/"
  }, "매도글 #S153 컬리 | 안전한 장외주식 PSX")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://platum.kr/archives/141404"
  }, "전세계 유니콘 기업 436개사...한국에는 11개사 서식 - 'Startup's Story Platform’")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
